import { all, call, select, take, put } from 'redux-saga/effects';

import userActions from '../../../../../../redux/user/actions';
import { scheduleActions } from "../actions";


import { setBaseFilterFromCache } from './caching/setBaseFilterFromCache';
import { setSelectedTagsFromCache } from './caching/setSelectedTagsFromCache';
import { setSelectedCrewMemberIdsFromCache } from './caching/setSelectedCrewMemberIdsFromCache';
import { setSelectedMaintenanceMemberIdsFromCache } from './caching/setSelectedMaintenanceMemberIdsFromCache';
import { setSelectedTimelinesFromCache } from './caching/setSelectedTimelinesFromCache';
import { setCrewSortOptionFromCache } from './caching/setCrewSortOptionFromCache';
import { setMaintenanceSortOptionFromCache } from './caching/setMaintenanceSortOptionFromCache';

import { loadPilots } from './pilots/loadPilots';
import { fetchCompanyScheduleVersionWorker } from '../../components/Versioning/redux-saga/workers/fetchCompanyScheduleVersionWorker';
import { setPreferredVersionWorker } from './versioning/setPreferredVersionWorker';
import { fetchCompanyTagsWorker } from './tags/fetchCompanyTagsWorker';
import { fetchAllEntries } from './timelineEntries/fetchAllEntries';
import { setVisibleTimelinesWorker } from './timeline/setVisibleTimelinesWorker';
import { setDisplayOptionsFromCache } from './caching/setDisplayOptionsFromCache';
import { setCurrentViewFromCache } from './caching/setCurrentViewFromCache';
import { loadClientContracts } from './contracts/loadClientContracts';
import { loadMaintenance } from './maintenance/loadMaintenance';

export function* onPageLoadedWorker() {
  const { isCompanyLoaded, isUserLoaded } = yield select(({ user }) => user);

  const awaitActions = [];
  if (!isCompanyLoaded) {
    awaitActions.push(take(userActions.SET_COMPANY));
  }
  if (!isUserLoaded) {
    awaitActions.push(take(userActions.SET_USER));
  }
  // await for company or user to be loaded
  yield all(awaitActions);

  yield all([
    call(setBaseFilterFromCache),
    call(setSelectedTagsFromCache),
    call(setSelectedCrewMemberIdsFromCache),
    call(setSelectedMaintenanceMemberIdsFromCache),
    call(setSelectedTimelinesFromCache),
    call(setCrewSortOptionFromCache),
    call(setMaintenanceSortOptionFromCache),
    call(setDisplayOptionsFromCache),
    call(setCurrentViewFromCache),
    call(setVisibleTimelinesWorker),
  ]);

  yield all([
    call(loadPilots),
    call(loadMaintenance),
    call(fetchCompanyScheduleVersionWorker),
    call(setPreferredVersionWorker),
    call(loadClientContracts),
  ]);

  yield call(fetchCompanyTagsWorker);
  yield put(scheduleActions.reFetchAllEntries());
}
