import {
  ShoppingCartOutlined,
  BookOutlined,
  BankOutlined,
  ScheduleOutlined,
  UserOutlined,
  EditOutlined,
  LineChartOutlined,
  CheckSquareFilled,
  BookFilled,
  SettingFilled,
  ClockCircleFilled,
  ClockCircleOutlined,
  FileProtectOutlined,
  FileTextFilled,
  FormOutlined,
  UnorderedListOutlined,
  GroupOutlined,
} from '@ant-design/icons';
import { isPathAllowedForRole } from 'utils/authorization';
import { isMobile } from 'react-device-detect';
import AirplaneModeActive from '../components/icons/AirplaneModeActive.svg';
import Airport from '../components/icons/Airport.svg';
import FlightTakeoff from '../components/icons/FlightTakeoff.svg';
import Page from '../components/icons/Page.svg';
import People from '../components/icons/People.svg';
import ScheduleIcon from '../components/icons/ScheduleIcon.svg';
import api from './api';

const getMenuData = async (roles, companySettings) => {
  const menu = [];
  let data = {};
  try {
    const response = await api.get('/v1/dashboard/menu');
    if (response.data && response.data.Data) {
      data = response.data.Data;
    }
  } catch (e) {
    console.log(e);
  }

  const checkPath = path => {
    return isPathAllowedForRole(roles, path, companySettings);
  };

  const { ShowAerochexLogs, ShowPilot, CrewSchedulingOnly } = data;
  const sales = {
    title: 'Sales',
    key: 'sales',
    iconComponent: ShoppingCartOutlined,
  };

  sales.children = [
    checkPath('/data/organization/company-clients') && {
      title: 'Clients',
      key: 'companyClients',
      url: '/data/organization/company-clients',
    },
    checkPath('/data/organization/invoices') && {
      title: 'Invoices',
      key: 'invoices',
      url: '/data/organization/invoices',
    },
  ];

  if (companySettings.SalesModule) {
    menu.push(sales, { divider: true });
  }

  const organization = {
    title: 'Organization',
    key: 'organization',
    iconComponent: BankOutlined,
  };

  organization.children = [
    checkPath('/data/organization/dashboard') && {
      title: 'Dashboard',
      key: 'dashboard',
      url: '/data/organization/dashboard',
      iconComponent: LineChartOutlined,
    },

    checkPath('/data/organization/logs') && {
      title: 'Logs',
      key: 'logs',
      url: '/data/organization/logs',
      iconComponent: BookOutlined,
    },
    checkPath('/data/organization/aircraft') && {
      title: 'Aircraft',
      key: 'aircraft',
      url: '/data/organization/aircraft',
      iconComponent: AirplaneModeActive,
    },
    checkPath('/data/organization/fleet-overview') && {
      title: 'Fleet Overview',
      key: 'fleet-overview',
      url: '/data/organization/fleet-overview',
      iconComponent: AirplaneModeActive,
    },
    checkPath('/data/organization/duty-times') && {
      title: 'Duty Times',
      key: 'duty-times',
      url: '/data/organization/duty-times',
      iconComponent: ScheduleOutlined,
    },
    checkPath('/data/organization/users') && {
      title: 'Users',
      key: 'users',
      url: '/data/organization/users',
      iconComponent: People,
    },
    checkPath('/data/organization/crew-compliance') && {
      title: 'Crew Compliance',
      key: 'crew-compliance',
      url: '/data/organization/crew-compliance/duty-times',
      iconComponent: ScheduleOutlined,
    },
    checkPath('/data/organization/crew-scheduling') && {
      title: companySettings.MaintenanceSchedule ? 'Schedule' : 'Crew Schedule',
      key: 'crew-scheduling',
      url: '/data/organization/crew-scheduling',
      iconComponent: ScheduleIcon,
    },
    ((isMobile && companySettings.ShowDesktopScheduleOnMobile) || !isMobile) &&
    checkPath('/data/organization/crew-scheduling-v3') && {
        title: companySettings.MaintenanceSchedule ? 'Schedule' : 'Crew Schedule',
        key: 'crew-scheduling-v3',
        url: '/data/organization/crew-scheduling-v3',
        iconComponent: ScheduleIcon,
      },
    isMobile &&
      checkPath('/data/organization/crew-scheduling-mobile') && {
        title: 'Crew Schedule (Mobile)',
        key: 'crew-scheduling-mobile',
        url: '/data/organization/crew-scheduling-mobile',
        iconComponent: ScheduleIcon,
      },
    checkPath('/data/organization/regulations') && {
      title: 'Regulations',
      key: 'regulations',
      url: '/data/organization/regulations',
      iconComponent: CheckSquareFilled,
    },
    checkPath('/data/organization/reports') && {
      title: 'Reports',
      key: 'reports',
      url: '/data/organization/reports',
      iconComponent: BookFilled,
    },
    checkPath('/data/organization/settings') && {
      title: 'Settings',
      key: 'settings',
      url: '/data/organization/settings',
      iconComponent: SettingFilled,
    },
    checkPath('/data/organization/flight-watch') && {
      title: 'Flight Watch',
      key: 'flightWatch',
      url: '/data/organization/flight-watch',
      iconComponent: FlightTakeoff,
    },
    checkPath('/data/organization/pilot-status') && {
      title: 'Pilot Status',
      key: 'pilotStatus',
      url: '/data/organization/pilot-status',
      iconComponent: ClockCircleFilled,
    },
    checkPath('/data/organization/company-library') && {
      title: 'Company Library',
      key: 'companyLibrary',
      url: '/data/organization/company-library',
      iconComponent: FileTextFilled,
    },
    checkPath('/data/organization/custom-forms') && {
      title: 'Custom Forms',
      key: 'customForms',
      url: '/data/organization/custom-forms',
      iconComponent: FormOutlined,
    },
    checkPath('/data/organization/tracked-items') && {
      title: 'Tracked Items',
      key: 'trainingItems',
      url: '/data/organization/tracked-items',
      iconComponent: UnorderedListOutlined,
    },
  ];

  if (companySettings.SalesModule) {
    organization.children.unshift(checkPath('/data/organization/groups') && {
      title: 'Bases',
      key: 'groups',
      url: '/data/organization/groups',
      iconComponent: GroupOutlined,
    },);
  }

  menu.push(organization, { divider: true });

  if (ShowPilot) {
    menu.push({
      title: 'My Profile',
      key: 'me',
      iconComponent: UserOutlined,
      children: [
        checkPath('/profile/manage') && {
          title: 'Manage Profile',
          key: 'me/manage',
          url: '/profile/manage',
          iconComponent: EditOutlined,
        },
        checkPath('/profile/duty') && {
          title: 'Flight And Duty',
          key: 'me/duty',
          url: '/profile/duty',
          iconComponent: ScheduleOutlined,
        },
      ],
    });
  }

  if (ShowAerochexLogs) {
    organization.children.push(
      checkPath('/data/organization/aerochex') && {
        title: 'Aerochex',
        key: 'aerochex',
        url: '/data/organization/aerochex',
      },
    );
  }

  menu.push(
    checkPath('/data/airports') && {
      title: 'Airports',
      key: 'airports',
      url: '/data/airports',
      iconComponent: Airport,
    },
  );

  // menu.push(
  //   {
  //     title: 'Settings',
  //     key: 'settings',
  //     url: '/settings',
  //     iconComponent: SettingOutlined,
  //     children :[
  //       {
  //         title: 'Timezone',
  //         key: 'settings_timezone',
  //         url: '/settings/timezone',
  //         iconComponent: ClockCircleOutlined,
  //       }
  //     ]
  //   },
  // );

  return menu
    .filter(x => x)
    .map(menuItem => ({ ...menuItem, children: menuItem.children?.filter(x => x) }))
    .filter(item => item?.children?.length > 0); // remove null or undefined children and items with no children
};

export default getMenuData;
