import { DutyScheduleEntry } from '../../../../redux/pages/crew-scheduling-v3/scheduleEntries/types/DutyScheduleEntry';
import { DutyTimelineEntry } from '../../../../common/types/timeline/DutyTimelineEntry';
import moment from 'moment-timezone';
import { DutyTimeEntry } from '../../../../common/types/dutyEntries';
import { FeatureFlag } from '../../../../utils/feature-flags/FeatureFlagsProvider';

export const arraysIntersect = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }

  if (arr1.length > arr2.length) {
    [arr1, arr2] = [arr2, arr1];
  }

  const set2 = new Set(arr2);

  for (const element of arr1) {
    if (set2.has(element)) {
      return true;
    }
  }

  return false;
}

export const filterOption = (input: string, option: { children: string | string[] }): boolean => {
  const inputLower = input.toLowerCase();

  if (Array.isArray(option.children)) {
    const concatenatedChildren = option.children.join('').toLowerCase();
    return concatenatedChildren.indexOf(inputLower) !== -1;
  }

  return option.children.toLowerCase().indexOf(inputLower) !== -1;
};

export const adjustDutyTimes = (
  ds: DutyScheduleEntry | DutyTimelineEntry | DutyTimeEntry,
  currentItemStart: number,
  currentItemEnd: number,
  newItemStart: number,
  newItemEnd: number,
  featureFlags: { [key: string]: boolean },
): DutyScheduleEntry | DutyTimelineEntry | DutyTimeEntry => {
  const format = 'format';
  const fdpEndTime = !!ds.FlightDutyPeriodEndTime ? moment(ds.FlightDutyPeriodEndTime) : null;

  const isChangingStart = currentItemStart !== newItemStart;
  const isChangingEnd = currentItemEnd !== newItemEnd;
  const isChangingStartHoW = isChangingStart && moment(ds.StartTime).isBefore(ds.RAPStartTime);
  const isChangingEndHoW = isChangingEnd && moment(ds.EndTime).isAfter(ds.RAPEndTime);

  // Calculate the difference in time for the move or resize action
  const timeDifferenceStart = newItemStart - currentItemStart;
  const timeDifferenceEnd = newItemEnd - currentItemEnd;

  const updateTimes = (
    startKey = 'RAPStartTime',
    endKey = 'RAPEndTime',
    updateOnlyRAPRestKeys = false,
  ) => {
    if (isChangingStart && isChangingEnd) {
      ds[startKey] = moment(ds[startKey])
        .add(timeDifferenceStart, 'milliseconds')
        [format]();
      if (!updateOnlyRAPRestKeys) {
        ds.StartTime = moment(ds.StartTime)
          .add(timeDifferenceStart, 'milliseconds')
          [format]();
      }

      ds[endKey] = moment(ds[endKey])
        .add(timeDifferenceEnd, 'milliseconds')
        [format]();

      if (!updateOnlyRAPRestKeys) {
        ds.EndTime = moment(ds.EndTime)
          .add(timeDifferenceEnd, 'milliseconds')
          [format]();
      }
    } else if (isChangingStart) {
      if (isChangingStartHoW) {
        if (!updateOnlyRAPRestKeys) {
          ds.StartTime = moment(ds.StartTime)
            .add(timeDifferenceStart, 'milliseconds')
            [format]();
        }
      } else {
        ds[startKey] = moment(ds[startKey])
          .add(timeDifferenceStart, 'milliseconds')
          [format]();
      }
    } else if (isChangingEnd) {
      if (isChangingEndHoW) {
        if (!updateOnlyRAPRestKeys) {
          ds.EndTime = moment(ds.EndTime)
            .add(timeDifferenceEnd, 'milliseconds')
            [format]();
        }
      } else {
        ds[endKey] = moment(ds[endKey])
          .add(timeDifferenceEnd, 'milliseconds')
          [format]();
      }
    }
  };

  if (ds.RAPStartTime && ds.RAPEndTime && ds.StartTime && ds.EndTime && !('RestStartTime' in ds)) {
    updateTimes();
  } else if (
    ds.RAPStartTime &&
    ds.RAPEndTime &&
    'RestStartTime' in ds &&
    ds.RestStartTime &&
    ds.RestEndTime &&
    ds.StartTime &&
    ds.EndTime
  ) {
    updateTimes();
    updateTimes('RestStartTime', 'RestEndTime', true);
  } else if (
    'RestStartTime' in ds &&
    ds.RestStartTime &&
    ds.RestEndTime &&
    ds.StartTime &&
    ds.EndTime
  ) {
    updateTimes('RestStartTime', 'RestEndTime');
  } else if (!ds.RAPStartTime && !ds.RAPEndTime && ds.StartTime && ds.EndTime) {
    ds.StartTime = moment(newItemStart)[format]();
    ds.EndTime = moment(newItemEnd)[format]();
  } else if (ds.RAPStartTime && ds.RAPEndTime && !ds.StartTime && !ds.EndTime) {
    ds.RAPStartTime = moment(ds.RAPStartTime)
      .add(timeDifferenceStart, 'milliseconds')
      [format]();
    ds.StartTime = ds.EndTime = ds.RAPStartTime;
    ds.RAPEndTime = moment(ds.RAPEndTime)
      .add(timeDifferenceEnd, 'milliseconds')
      [format]();
  } else if (ds.RAPStartTime && ds.RAPEndTime && ds.StartTime && ds.EndTime) {
    ds.RAPStartTime = moment(ds.RAPStartTime)
      .add(timeDifferenceStart, 'milliseconds')
      [format]();
    ds.StartTime = moment(ds.StartTime)
      .add(timeDifferenceStart, 'milliseconds')
      [format]();

    ds.RAPEndTime = moment(ds.RAPEndTime)
      .add(timeDifferenceEnd, 'milliseconds')
      [format]();

    ds.EndTime = moment(ds.EndTime)
      .add(timeDifferenceEnd, 'milliseconds')
      [format]();
  }

  if ((isChangingStart && isChangingEnd) || isChangingEndHoW) {
    ds.FlightDutyPeriodEndTime = fdpEndTime
      ? moment(fdpEndTime)
          .add(timeDifferenceEnd, 'milliseconds')
          [format]()
      : null;
  }

  return ds;
};

export const isHideCSEntryDetails = (
  featureFlags: { [key: string]: boolean },
  userRoles: string[],
): boolean => {
  const isAdmin = userRoles.includes('admin');
  const isPilot = userRoles.includes('pilot');
  return featureFlags[FeatureFlag.HideCrewScheduleEntryDetailsForPilots] && isPilot && !isAdmin;
};

export const isHideCSEntryDetailsForUser = (
  featureFlags: { [key: string]: boolean },
  userRoles: string[],
  authUserID: string,
  entryUserID: string,
): boolean => {
  return isHideCSEntryDetails(featureFlags, userRoles) && authUserID !== entryUserID;
};

export const isHideMaintenanceEntryDetails = (
  featureFlags: { [key: string]: boolean },
  userRoles: string[],
): boolean => {
  const isAdmin = userRoles.includes('admin');
  const isEngineer = userRoles.includes('engineer');
  return (
    featureFlags[FeatureFlag.HideMaintenanceScheduleEntryDetailsForMaintenance] &&
    isEngineer &&
    !isAdmin
  );
};

export const isHideMaintenanceEntryDetailsForUser = (
  featureFlags: { [key: string]: boolean },
  userRoles: string[],
  authUserID: string,
  entryUserID: string,
): boolean => {
  return isHideMaintenanceEntryDetails(featureFlags, userRoles) && authUserID !== entryUserID;
};
