import { put } from 'redux-saga/effects';
import LocalCache from '../../../../../../local-storage/local-storage';
import { scheduleActions } from '../../actions';
import { selectUserSaga } from '../../../../../../../common/saga/selectors';

export function* setBaseFilterFromCache() {
  const user = yield* selectUserSaga();

  const localCache = new LocalCache('crewSchedulingV2', user);
  yield put(scheduleActions.setBaseFilter(localCache.getCached('baseFilter', [])));
}
