import { get } from 'lodash';
import { FeatureFlag, useFeatureFlags } from 'utils/feature-flags/FeatureFlagsProvider';
import { useSelector } from 'react-redux';
import { getCompany, getUser, getUserRole } from 'redux/selectors';
import permissions from './constant';
import { DefinedPermissions } from '../../common/helpers/permissions';

class Guard {
  permissionList = [];

  roleRank = {
    owner: 1,
    admin: 2,
    viewOnly: 3,
    pilot: 4,
  };

  constructor(module) {
    this.userRoles = useSelector(getUserRole);
    this.user = useSelector(getUser);
    this.company = useSelector(getCompany);
    this.featureFlags = useFeatureFlags();

    this.getPermissions();

    this.module = module;
    this.localStorage = window.localStorage;
  }

  getPermissions() {
    const permissionSet = { ...permissions };
    permissionSet.auth.user = this?.user || null;
    const definedPermissions = this?.user?.CompanyUsers[0]?.DefinedPermissions || [];
    if (this.userRoles?.includes('subContractor')) {
      permissionSet.crewScheduleV2.actions.addAircraftContract = false;
      permissionSet.crewScheduleV2.actions.addPilotContract = false;
      permissionSet.crewScheduleV2.actions.addDutyEntry = false;
      permissionSet.crewScheduleV2.actions.addDutyTime = false;
      permissionSet.crewScheduleV2.actions.addScheduleEntry = false;
      permissionSet.crewScheduleV2.actions.addMaintenanceEntry = false;
      permissionSet.crewScheduleV2.filters.companyClient = false;
      permissionSet.crewScheduleV2.filters.crewCertification = false;
      permissionSet.crewScheduleV2.filters.maintenanceCertification = false;
      permissionSet.crewScheduleV2.filters.crewMember = false;
      permissionSet.crewScheduleV2.filters.maintenanceMember = false;
      permissionSet.crewScheduleV2.filters.crewMemberBase = false;
      permissionSet.crewScheduleV2.filters.companyTag = false;
      permissionSet.crewScheduleV2.filters.displayOption = false;
      permissionSet.crewScheduleV2.timelines.crewTimeline = false;
      permissionSet.crewScheduleV2.timelines.viewAircraftContracts = false;

      permissionSet.crewScheduleV2.allowed.aircraftTails = this.user.CompanyUsers?.[0]?.AircraftTails;
    } else {
      permissionSet.crewScheduleV2.actions.addScheduleEntry = true;
      permissionSet.crewScheduleV2.actions.addDutyEntry = true;
      permissionSet.crewScheduleV2.actions.addMaintenanceEntry = true;
      // permissionSet.crewScheduleV2.actions.addDutyTime = true

      permissionSet.crewScheduleV2.actions.updateScheduleEntry = true;
      permissionSet.crewScheduleV2.actions.updateDutyEntry = true;
      permissionSet.crewScheduleV2.actions.updateMaintenanceEntry = true;
      // permissionSet.crewScheduleV2.actions.updateDutyTime = true

      permissionSet.crewScheduleV2.actions.deleteScheduleEntry = true;
      permissionSet.crewScheduleV2.actions.deleteMaintenanceEntry = true;
      permissionSet.crewScheduleV2.actions.deleteDutyEntry = true;
      // permissionSet.crewScheduleV2.actions.deleteDutyTime = true

      permissionSet.pages.flightWatch = true;
      permissionSet.pages.pilotStatus = true;

      if (this.userRoles?.includes('admin')) {
        // only allow admins to copy & paste
        permissionSet.crewScheduleV2.actions.copyAndPasteEntry = true;
        permissionSet.aircraftLogDetails.actions.editLogDetails = true;
        permissionSet.pages.companyLibrary = true;
        permissionSet.pages.customForms = true;
        permissionSet.pages.trainingItems = true;
        permissionSet.pages.companyClients = true;
        permissionSet.pages.invoices = true;
        permissionSet.pages.groups = true;
      }

      if (this.userRoles?.includes('pilot')) {
        permissionSet.pages.companyLibrary = true;
        permissionSet.pages.trainingItems = true;
      }

      if (
        definedPermissions.includes(DefinedPermissions.ALLOW_COMPLIANCE_OVERRIDE) &&
        this.featureFlags.get(FeatureFlag.ComplianceManagementOverride)
      ) {
        permissionSet.crewScheduleV2.actions.overrideEntryCompliance = true;
      }

      if (this.userRoles?.includes('reportViewer')) {
        // hide the regualations, users and settings option from the menu
        permissionSet.pages.regulations = false;
        permissionSet.pages.users = false;
        permissionSet.pages.settings = false;
        permissionSet.aircraftLogDetails.actions.editLogDetails = true;
      }

      if (
        this.userRoles?.includes('crewScheduleViewer') ||
        this.userRoles?.includes('taggedScheduleViewer')
      ) {
        permissionSet.crewScheduleV2.timelines.crewTimeline = true;

        if (!this.userRoles?.includes('pilot')) {
          permissionSet.crewScheduleV2.actions.addDutyEntry = false;
          permissionSet.crewScheduleV2.actions.addDutyTime = false;
        }
        permissionSet.crewScheduleV2.filters.displayOption = false;
        permissionSet.crewScheduleV2.allowed.displayOptions = {
          self: ['crew-schedule-entries', 'duty-time-entries', 'duty-schedule-entries'],
          others: ['crew-schedule-entries'],
        };
        permissionSet.crewScheduleV2.allowed.tags = this?.user?.CompanyUsers?.[0].AllowedTags ?? [];
      }

      // for version only
      if (this.company?.Settings?.Versioning) {
        const preferredVersion = this.user?.CompanyUsers[0]?.PreferredVersion;
        const versionIsSandbox = preferredVersion.startsWith('sandbox');
        const versionIsCurrent = !preferredVersion.startsWith('latest');
        if (versionIsCurrent && !versionIsSandbox) {
          permissionSet.crewScheduleV2.actions.addScheduleEntry = false;
          permissionSet.crewScheduleV2.actions.addMaintenanceEntry = false;
          permissionSet.crewScheduleV2.actions.addDutyEntry = false;
          // permissionSet.crewScheduleV2.actions.addDutyTime = false

          permissionSet.crewScheduleV2.actions.updateScheduleEntry = false;
          permissionSet.crewScheduleV2.actions.updateMaintenanceEntry = false;
          permissionSet.crewScheduleV2.actions.updateDutyEntry = false;
          // permissionSet.crewScheduleV2.actions.updateDutyTime = false

          permissionSet.crewScheduleV2.actions.deleteScheduleEntry = false;
          permissionSet.crewScheduleV2.actions.deleteDutyEntry = false;
          permissionSet.crewScheduleV2.actions.deletePilotContractEntry = false;
          permissionSet.crewScheduleV2.actions.deleteMaintenanceEntry = false;
          // permissionSet.crewScheduleV2.actions.deleteDutyTime = false

          permissionSet.crewScheduleV2.actions.copyAndPasteEntry = false;
        }
      }
    }

    if (this.company?.Settings?.CrewScheduleHiddenFilters) {
      // eslint-disable-next-line no-restricted-syntax
      for (const f of this.company?.Settings?.CrewScheduleHiddenFilters) {
        permissionSet.crewScheduleV2.filters[f] = false;
      }
    }
    if (this.company?.Settings?.CrewScheduleHiddenFields) {
      // eslint-disable-next-line no-restricted-syntax
      for (const f of this.company?.Settings?.CrewScheduleHiddenFields) {
        permissionSet.crewScheduleV2.fields[f] = false;
      }
    }

    this.permissionList = permissionSet;
  }

  canAccess(permission) {
    return get(this.permissionList, `${this.module}.${permission}`, false);
  }

  canAccessFilter(filterName) {
    return get(this.permissionList, `${this.module}.filters.${filterName}`, false);
  }

  canAccessField(fieldName) {
    return get(this.permissionList, `${this.module}.fields.${fieldName}`, false);
  }

  getAllowed(item) {
    return get(this.permissionList, `${this.module}.allowed.${item}`, null);
  }

  getAuthUser() {
    return get(this.permissionList, `auth.user`, null);
  }
}

export default Guard;
