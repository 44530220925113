import { scheduleActions } from '../../actions';
import { put } from 'redux-saga/effects';
import {
  selectCompanySettingsSaga,
  selectUserRoleSaga,
} from '../../../../../../../common/saga/selectors';
import { CompanySettings } from '../../../../../../../common/types/Company';
import { TimelineType } from '../../../types/timeline';

export function* setVisibleTimelinesWorker() {
  const companySettings = yield* selectCompanySettingsSaga();
  const userRoles = yield* selectUserRoleSaga();

  const visibleTimelines = []

  if(!userRoles.includes('subContractor')){
    visibleTimelines.push(TimelineType.Crew)
  }
  if (isDisplayContractTimeline(userRoles, companySettings)) {
    visibleTimelines.push(TimelineType.Contract);
  }

  if (isDisplayAircraftTimeline(userRoles, companySettings)) {
    visibleTimelines.push(TimelineType.Aircraft);
  }
  if (isDisplayMaintenanceTimeline(userRoles, companySettings)) {
    visibleTimelines.push(TimelineType.Maintenance);
  }
  if (isDisplayGroupTimeline(userRoles, companySettings)) {
    visibleTimelines.push(TimelineType.AircraftGroup);
  }

  yield put(scheduleActions.setVisibleTimelines(visibleTimelines));
}

export const isDisplayAircraftTimeline = (
  userRoles: string[],
  companySettings: CompanySettings,
) => {
  if (companySettings?.ScheduleByBase) {
    return false;
  }

  if (
    userRoles?.includes('admin') ||
    userRoles?.includes('subContractor') ||
    userRoles?.includes('taggedScheduleViewer')
  ) {
    return true;
  }

  if (
    companySettings?.PilotCanViewAircraftSchedule &&
    (userRoles?.includes('viewOnly') || userRoles?.includes('engineer'))
  ) {
    return true;
  }

  if (
    userRoles?.includes('viewOnly') &&
    !(userRoles?.includes('pilot') || userRoles?.includes('engineer'))
  ) {
    return true;
  }

  if (userRoles?.includes('pilot') && companySettings?.PilotCanViewAllPilotsCrewSchedule) {
    return true;
  }

  return false;
};

export const isDisplayMaintenanceTimeline = (
  userRoles: string[],
  companySettings: CompanySettings,
) => {

  if(!companySettings?.MaintenanceSchedule){
    return false
  }

  if (
    userRoles?.includes('admin') ||
    userRoles?.includes('engineer') ||
    userRoles?.includes('taggedMaintenance')
  ) {
    return true;
  }

  if (
    companySettings?.MaintenanceCanViewAllMaintenanceMembersSchedule &&
    userRoles?.includes('viewOnly')
  ) {
    return true;
  }

  if (userRoles?.includes('viewOnly') && !userRoles?.includes('engineer')) {
    return true;
  }

  if (
    userRoles?.includes('engineer') &&
    companySettings?.MaintenanceCanViewAllMaintenanceMembersSchedule
  ) {
    return true;
  }

  return false;
};

export const isDisplayContractTimeline = (
  userRoles: string[],
  companySettings: CompanySettings,
) => {
  if (!companySettings?.ContractSchedule) {
    return false;
  }

  return true;
};

export const isDisplayGroupTimeline = (
  userRoles: string[],
  companySettings: CompanySettings,
) => {
  if (!companySettings?.AllowGroupSchedule) {
    return false;
  }

  return true;
};
