import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select } from 'antd';
import HasAccess from '../../../../permissions/guard-access-component';
import { CompanyClientSelector } from '../../../../../components/shared/CompanyClientSelector/CompanyClientSelector';
import Guard from '../../../../permissions/crew-schedule-v2-guard';
import { filterOption } from '../helpers';
import { getCompany, getUser, getUserRole } from '../../../../../redux/selectors';
import {
    getBaseFilter,
  getCompanyTags,
  getEntryDisplayOptions,
  getIsDrawerVisible,
  getSelectedClientContracts,
  getSelectedClients,
  getSelectedTags,
  getVisibleTimelines,
} from '../redux-saga/selectors';
import { scheduleActions } from '../redux-saga/actions';
import { CrewSelector } from './CrewSelector/CrewSelector';
import { TimelineType } from '../types/timeline';
import { IDs } from '../../../../../constants';
import { CrewTimelineEntryType } from '../../../../../common/types/timeline/CrewTimelineEntryType';
import { AircraftTimelineEntryType } from '../../../../../common/types/timeline/AircraftTimelineEntryType';
import { AircraftTypeSelector } from './AircraftTypeSelector/AircraftTypeSelector';
import LocalCache from '../../../../local-storage/local-storage';
import { CrewScheduleFilter } from '../../../../../common/constants/filters';
import { ClientContractSelector } from '../../../../../components/shared/ClientContractSelector/ClientContractSelector';
import { getFieldLabel } from '../../../../../common/helpers/company';
import { CompanyFieldNames } from '../../../../../common/constants/company';
import { MaintenanceSelector } from './MaintenanceSelector/MaintenanceSelector';
import { getAircraftGroupSchedule } from './GroupSchedule/redux-saga/selectors';

const { Option } = Select;

export const CrewSchedulingFilters = () => {
  const dispatch = useDispatch();
  const company = useSelector(getCompany);
  const companySettings = company.Settings;
  const user = useSelector(getUser);
  const userRoles = useSelector(getUserRole);
  const selectedTags = useSelector(getSelectedTags);
  const companyTags = useSelector(getCompanyTags);
  const selectedClients = useSelector(getSelectedClients);
  const selectedClientContracts = useSelector(getSelectedClientContracts);
  const visibleTimelines = useSelector(getVisibleTimelines);
  const entryDisplayOptions = useSelector(getEntryDisplayOptions);
  const localCache = new LocalCache('crewSchedulingV2', user);
  const isDrawerVisible = useSelector(getIsDrawerVisible);
  const marginStyle = !isDrawerVisible ? '20px' : '';

  const baseFilter = useSelector(getBaseFilter);
  const { aircraftGroupList } = useSelector(getAircraftGroupSchedule);

  const guard = new Guard('crewScheduleV2');

  const isAircraftTimelineVisible = visibleTimelines.includes(TimelineType.Aircraft);

  const isDisplayMaintenanceFilters = () => {
    if (!companySettings.MaintenanceSchedule) {
      return false;
    }

    if (userRoles.includes('admin') || userRoles.includes('engineer')) {
      return true;
    }
    if (
      userRoles?.includes('crewScheduleViewer') ||
      userRoles?.includes('taggedScheduleViewer') ||
      companySettings?.MaintenanceCanViewAllMaintenanceMembersSchedule
    ) {
      if (userRoles.includes('engineer') || !userRoles.includes('admin')) {
        return true;
      }
    }

    if (userRoles.includes('viewOnly') && !userRoles.includes('engineer')) {
      return true;
    }

    return false;
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: isDrawerVisible ? 'column' : 'row', width: '100%' }}
    >
      <CrewSelector />
      {isDisplayMaintenanceFilters() && <MaintenanceSelector />}
      <AircraftTypeSelector aircraftTimelineVisibility={isAircraftTimelineVisible} />

      {companySettings.AllowGroupSchedule &&
        <Form.Item
          label="Base"
          style={{ marginRight: marginStyle, flexGrow: 1, flexBasis: 0 }}
        >
          <Select
            id="base-filter"
            mode="multiple"
            filterOption={filterOption}
            onChange={selectedBases => {
              dispatch(scheduleActions.setBaseFilter(selectedBases));
              localCache.setCached('baseFilter', selectedBases);
            }}
            allowClear
            placeholder="Select Bases"
            value={baseFilter}
          >
            {aircraftGroupList.map((g) => {
              return (
                <Option
                  key={g.ID}
                  value={g.ID}
                >
                  {g.Name}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
      }

      <HasAccess access={guard.canAccessFilter(CrewScheduleFilter.DISPLAY_OPTIONS)}>
        <Form.Item
          label="Display Options"
          style={{ marginRight: marginStyle, flexGrow: 1, flexBasis: 0 }}
        >
          <Select
            id={IDs.filters.displayOptionsSelector}
            mode="multiple"
            maxTagCount="responsive"
            filterOption={filterOption}
            onChange={displayOptions => {
              dispatch(scheduleActions.setEntryDisplayOptions(displayOptions));
              localCache.setCached('displayOptions', displayOptions);
            }}
            allowClear
            placeholder="Select Display Options"
            value={entryDisplayOptions}
          >
            <Option
              key={CrewTimelineEntryType.DutySchedule}
              value={CrewTimelineEntryType.DutySchedule}
            >
              Planned Duty
            </Option>
            <Option key={CrewTimelineEntryType.DutyTimes} value={CrewTimelineEntryType.DutyTimes}>
              Actual Duty
            </Option>
            <Option
              key={CrewTimelineEntryType.PilotContract}
              value={CrewTimelineEntryType.PilotContract}
            >
              Pilot Contract
            </Option>
            <Option
              key={AircraftTimelineEntryType.AircraftContract}
              value={AircraftTimelineEntryType.AircraftContract}
            >
              {companySettings?.CompanyFieldNameMappings?.CrewScheduleAircraft || 'Aircraft'}{' '}
              Contract
            </Option>
            {companySettings.MaintenanceSchedule &&
              <Option
                key={AircraftTimelineEntryType.MaintenanceAircraftSchedule}
                value={AircraftTimelineEntryType.MaintenanceAircraftSchedule}
              >
                Maintenance {' '} {companySettings?.CompanyFieldNameMappings?.CrewScheduleAircraft || 'Aircraft'}
              </Option>
            }
          </Select>
        </Form.Item>
      </HasAccess>

      <HasAccess access={guard.canAccessFilter(CrewScheduleFilter.CLIENT)}>
        <Form.Item label="Client" style={{ marginRight: marginStyle, flexGrow: 1, flexBasis: 0 }}>
          <CompanyClientSelector
            id={IDs.filters.clientSelector}
            value={selectedClients}
            onChange={clientIds => {
              dispatch(scheduleActions.setSelectedClients(clientIds));
              localCache.setCached('selectedCompanyClients', clientIds);
            }}
            allowClear
            placeholder={'Select the client'}
            showSearch
            mode="multiple"
          />
        </Form.Item>
      </HasAccess>
      <HasAccess access={guard.canAccessFilter(CrewScheduleFilter.CLIENT_CONTRACT)}>
        <Form.Item
          label={getFieldLabel(companySettings, CompanyFieldNames.ClientContract, 'Contract')}
          style={{ marginRight: marginStyle, flexGrow: 1, flexBasis: 0 }}
        >
          <ClientContractSelector
            id={IDs.filters.clientContractSelector}
            value={selectedClientContracts}
            onChange={clientContractIDs => {
              dispatch(scheduleActions.setSelectedClientContracts(clientContractIDs as string[]));
              localCache.setCached('selectedClientContracts', clientContractIDs);
            }}
            allowClear
            placeholder={`Select ${getFieldLabel(
              companySettings,
              CompanyFieldNames.ClientContract,
              'Contract',
            )}`}
            showSearch
            mode="multiple"
          />
        </Form.Item>
      </HasAccess>

      <HasAccess access={guard.canAccessFilter(CrewScheduleFilter.TAG)}>
        <Form.Item label="Tags" style={{ flexGrow: 1, flexBasis: 0 }}>
          <Select
            id={IDs.filters.tagsSelector}
            mode="multiple"
            placeholder="Select the tags"
            showSearch
            filterOption={filterOption}
            value={selectedTags}
            onChange={tags => {
              dispatch(scheduleActions.setSelectedTags(tags));
              localCache.setCached('selectedTags', tags)
            }}
            disabled={userRoles.includes('crewScheduleViewer')}
            allowClear
            listHeight={320}
          >
            {companyTags.map(tag => (
              <Option key={tag.ID} value={tag.ID}>
                {tag.Name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </HasAccess>
    </div>
  );
};
